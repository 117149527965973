export function getYear() {
    const date = new Date();
    return date.getFullYear();
}

export function getMonth() {
    const date = new Date();
    return date.getMonth() + 1 >= 10
        ? date.getMonth() + 1
        : "0" + (date.getMonth() + 1);
}
export function getDay() {
    const date = new Date();
    return getzf(date.getDate());
}

export function getMyDate(str) {
    const oDate = new Date(str);
    const oYear = oDate.getFullYear();
    const oMonth = oDate.getMonth() + 1;
    const oDay = oDate.getDate();
    const oHour = oDate.getHours();
    const oMin = oDate.getMinutes();
    const oSen = oDate.getSeconds();
    const oTime = getzf(oHour) + ":" + getzf(oMin) + ":" + getzf(oSen); // 最后拼接时间
    return oTime;
}

// 时间转换获得年月日时分秒,获得当前年月日时分秒
export function getMyDateFull(str) {
    var oDate = new Date(str);
    var oYear = oDate.getFullYear();
    var oMonth = oDate.getMonth() + 1;
    var oDay = oDate.getDate();
    var oHour = oDate.getHours();
    var oMin = oDate.getMinutes();
    var oSen = oDate.getSeconds();
    // 最后拼接时间
    return (
        oYear +
        "-" +
        getzf(oMonth) +
        "-" +
        getzf(oDay) +
        " " +
        getzf(oHour) +
        ":" +
        getzf(oMin) +
        ":" +
        getzf(oSen)
    );
}

export function getTimeSecond(date) {
    const oDate = new Date(date);
    const oHour = oDate.getHours();
    const oMin = oDate.getMinutes();
    const oSen = oDate.getSeconds();
    return oHour * 3600 + oMin * 60 + oSen;
}

export function getFormatTimeSecond(date) {
    const oDate = date.split(":");
    const oHour = oDate[0] * 3600;
    const oMin = oDate[1] * 60;
    const oSen = Number(oDate[2]);
    return oHour + oMin + oSen;
}

export function formatSeconds(times) {
    var result = "00:00:00";
    var hour, minute, second;
    if (times > 0) {
        hour = Math.floor(times / 3600);
        if (hour < 10) {
            hour = "0" + hour;
        }
        minute = Math.floor((times - 3600 * hour) / 60);
        if (minute < 10) {
            minute = "0" + minute;
        }

        second = Math.floor((times - 3600 * hour - 60 * minute) % 60);
        if (second < 10) {
            second = "0" + second;
        }
        result = hour + ":" + minute + ":" + second;
    }
    return result;
}

export function secondsToChinese(value) {
    var secondTime = parseInt(value); // 秒
    var minuteTime = 0; // 分
    var hourTime = 0; // 小时
    if (secondTime > 60) {
        // 如果秒数大于60，将秒数转换成整数
        // 获取分钟，除以60取整数，得到整数分钟
        minuteTime = parseInt(secondTime / 60);
        // 获取秒数，秒数取佘，得到整数秒数
        secondTime = parseInt(secondTime % 60);
        // 如果分钟大于60，将分钟转换成小时
        if (minuteTime > 60) {
            // 获取小时，获取分钟除以60，得到整数小时
            hourTime = parseInt(minuteTime / 60);
            // 获取小时后取佘的分，获取分钟除以60取佘的分
            minuteTime = parseInt(minuteTime % 60);
        }
    }
    var result = "" + parseInt(secondTime) + "秒";

    if (minuteTime > 0) {
        result = "" + parseInt(minuteTime) + "分" + result;
    }
    if (hourTime > 0) {
        result = "" + parseInt(hourTime) + "小时" + result;
    }
    return result;
}
// 获取excel里的时间转换成合适的格式
export function formatExcelDate(numb) {
    const time = new Date((numb - 1) * 24 * 3600000 + 1);
    time.setYear(time.getFullYear() - 70);
    time.setHours(time.getUTCHours());
    time.setDate(time.getDate() - 1);
    // const year = time.getFullYear() + ''
    // const month = time.getMonth() + 1 + ''
    // const date = time.getDate() - 1 + ''
    // const oHour = time.getTime()
    // const oMin = time.getMinutes()
    // const oSen = time.getSeconds()
    //console.log(getMyDateFull(time.getTime()));
    return getMyDateFull(time.getTime());
}
// 补0操作
export function getzf(num) {
    if (parseInt(num) < 10) {
        num = "0" + num;
    }
    return num;
}
