<template>
    <!--  <div class="dashboard-container">-->
    <el-dialog
        :visible.sync="show"
        width="90%"
        :append-to-body="true"
        :destroy-on-close="true"
        :close-on-click-modal="false"
        @close="close"
    >
        <!--    <el-card>-->
        <!--      <el-row type="flex" justify="start" :gutter="0">-->
        <!--        <el-col class="flex">-->
        <!--          <span class="text-primary text-pad-right" style="width: 100px">日期：</span>-->
        <!--          <el-date-picker-->
        <!--            v-model="condition.date"-->
        <!--            type="date"-->
        <!--            class="input searchInput"-->
        <!--            clearable-->
        <!--            value-format="yyyy-MM-dd"-->
        <!--            size="mini"-->
        <!--            @change="changeCondition"-->
        <!--          />-->
        <!--        </el-col>-->
        <!--      </el-row>-->
        <!--    </el-card>-->
        <el-card style="margin-top: 20px">
            <el-row>
                <vxe-toolbar :refresh="{ query: getList }">
                    <template v-slot:buttons>
                        <vxe-button @click="exportPdf">导出</vxe-button>
                    </template>
                </vxe-toolbar>
                <div id="pdf">
                    <div class="flex justify-center">
                        <div>
                            <span style="font-size: 24px; font-weight: 600"
                                >营运车辆“一日三检”安全行车日志</span
                            >
                        </div>
                    </div>
                    <el-row
                        type="flex"
                        justify="space-between"
                        style="
                            font-size: 20px;
                            font-weight: 600;
                            margin-top: 20px;
                        "
                    >
                        <el-col>
                            <span>车牌号：{{ row.carNum }}</span>
                        </el-col>
                        <el-col>
                            <span>线路：{{ row.carLine }}</span>
                        </el-col>
                        <el-col class="flex align-center">
                            <span class="text-pad-right">检查人</span>
                            <el-select
                                v-model="condition.userId"
                                size="mini"
                                class="input searchInput"
                                @change="getList"
                            >
                                <el-option
                                    v-for="(v, i) in userMsgList"
                                    :key="i"
                                    :value="v.userId"
                                    :label="v.realName"
                                />
                            </el-select>
                        </el-col>
                        <el-col class="flex align-center">
                            <span class="text-pad-right">行车状态</span>
                            <el-select
                                v-model="condition.status"
                                size="mini"
                                class="input searchInput"
                                @change="getList"
                            >
                                <el-option
                                    v-for="(v, i) in carStatus"
                                    :key="i"
                                    :value="v.dictCode"
                                    :label="v.dictName"
                                />
                            </el-select>
                        </el-col>
                        <el-col>
                            <span class="text-pad-right">行车日期</span>
                            <el-date-picker
                                v-model="condition.date"
                                type="date"
                                class="input searchInput"
                                value-format="yyyy-MM-dd"
                                :clearable="false"
                                size="mini"
                                @change="changeCondition"
                            />
                        </el-col>
                    </el-row>
                    <vxe-table
                        ref="table"
                        border
                        resizable
                        :auto-resize="true"
                        :header-row-style="headerStyle"
                        :row-style="rowStyle"
                        align="center"
                        class="vxe-table-element"
                        row-id="inspectionId"
                        :sort-config="{ remote: true }"
                        :filter-config="{ remote: true }"
                        :checkbox-config="{ reserve: true }"
                        :data="table.list"
                        :loading="loading"
                        style="margin-top: 20px"
                    >
                        <!--            <vxe-table-column type="seq" width="60" />-->
                        <vxe-table-column
                            field="inspectionItemName"
                            title="检查项"
                        />
                        <vxe-table-column
                            field="rectificationExplain"
                            title="存在问题"
                        >
                            <template v-slot="{ row }">
                                <span v-if="row.rectificationExplain">{{
                                    row.rectificationExplain
                                }}</span>
                                <span v-else>--</span>
                            </template>
                        </vxe-table-column>
                        <vxe-table-column
                            field="inspectionImgUrl"
                            title="检查照片"
                        >
                            <template v-slot="{ row }">
                                <el-popover
                                    v-if="row.inspectionImgUrl"
                                    placement="right"
                                    width="200"
                                    trigger="hover"
                                >
                                    <img
                                        :src="$fileUrl + row.inspectionImgUrl"
                                        alt=""
                                        style="width: 500px; height: 500px"
                                    />
                                    <img
                                        slot="reference"
                                        :src="$fileUrl + row.inspectionImgUrl"
                                        alt=""
                                        style="width: 100px; height: 100px"
                                    />
                                </el-popover>
                            </template>
                        </vxe-table-column>
                        <vxe-table-column title="检查结果">
                            <template v-slot="{ row }">
                                <span v-if="row.inspectionStatus === 0"
                                    >--</span
                                >
                                <span v-else>异常</span>
                            </template>
                        </vxe-table-column>
                        <vxe-table-column
                            field="rectificationResultsName"
                            title="整改结果"
                        >
                        </vxe-table-column>
                        <vxe-table-column field="modifyTime" title="整改时间">
                        </vxe-table-column>
                        <vxe-table-column
                            field="rectificationImgUrl"
                            title="整改后照片"
                        >
                            <template v-slot="{ row }">
                                <template v-if="row.rectificationImgUrl">
                                    <el-tooltip
                                        effect="light"
                                        popper-class="pop-left"
                                        placement="left-start"
                                        width="200"
                                        trigger="hover"
                                    >
                                        <img
                                            slot="content"
                                            :src="
                                                $fileUrl +
                                                row.rectificationImgUrl
                                            "
                                            alt=""
                                            style="width: 500px; height: 500px"
                                        />
                                        <img
                                            :src="
                                                $fileUrl +
                                                row.rectificationImgUrl
                                            "
                                            alt=""
                                            style="width: 100px; height: 100px"
                                        />
                                    </el-tooltip>
                                </template>
                                <template v-else>
                                    <span>--</span>
                                </template>
                            </template>
                        </vxe-table-column>
                    </vxe-table>
                    <div>
                        <span style="font-size: 20px; font-weight: 600"
                            >检查执行人：</span
                        >
                        <template v-if="carMsg.signUrl != null">
                            <el-popover
                                placement="right"
                                width="200"
                                trigger="hover"
                            >
                                <img :src="$fileUrl + carMsg.signUrl" alt="" />
                                <img
                                    slot="reference"
                                    :src="$fileUrl + carMsg.signUrl"
                                    alt=""
                                    style="width: 100px; height: 100px"
                                />
                            </el-popover>
                        </template>
                        <template v-else>
                            <span>--</span>
                        </template>
                    </div>
                </div>
            </el-row>
        </el-card>
    </el-dialog>
    <!--  </div>-->
</template>

<script>
import { rowStyle, headerStyle } from "@/utils/tableStyleJs";
import { carCheck, carCheckExcel, getDictParentCode } from "@/api";
import { getYear, getMonth, getDay } from "@/utils/getDate";
import { bytesToFile } from "@/utils/handleByte";
export default {
    name: "Index",
    components: {},
    props: {
        // 打开dialog的
        dialog: {
            type: Boolean, // 指定传入的类型
            // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
            default: false, // 这样可以指定默认的值
        },
        info: {
            type: Object, // 指定传入的类型
            // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
            default: () => {}, // 这样可以指定默认的值
        },
        row: {
            type: Object, // 指定传入的类型
            // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
            default: () => {}, // 这样可以指定默认的值
        },
    },
    data() {
        return {
            loading: false,
            show: this.dialog,
            condition: {
                date: "",
                userId: "",
                status: "0",
            },
            table: {
                list: [],
                total: 0,
                pageNum: 1,
                pageSize: 10,
                sortName: "",
                sortBy: "",
            },
            carMsg: {},
            carStatus: [],
            userMsgList: [],
        };
    },
    created() {
        this.condition.date = `${getYear()}-${getMonth()}-${getDay()}`;
        this.getList();
        this.getDictParentCode();
        this.getcarManage();
    },
    methods: {
        rowStyle,
        headerStyle,
        // 打开dialog
        openDialog(info, row) {
            this.dialog.refresh = true;
            this.dialog.show = true;
            this.dialog.info = info;
            this.dialog.row = row;
        },
        getcarManage() {
            this.$axiosReq(
                "/car/server/escort/web/carManage/" + this.row.id,
                null,
                null,
                "get"
            ).then((res) => {
                this.condition.userId = res.data.userMsg[0].userId;
                this.userMsgList = res.data.userMsg;
            });
        },
        // 打开批量导入
        openExport() {
            this.dialog.refresh2 = true;
            this.dialog.show2 = true;
        },
        openView(info, row) {
            this.dialog.refresh3 = true;
            this.dialog.show3 = true;
            this.dialog.info = info;
            this.dialog.row = row;
        },
        changeCondition() {
            this.table.pageNum = 1;
            this.getList();
        },
        getDictParentCode() {
            this.$axiosReq(
                getDictParentCode + "DRIVING_STATUS",
                null,
                null,
                "get"
            ).then((res) => {
                this.carStatus = res.data;
            });
        },
        exportPdf() {
            const loading = this.$loading({
                lock: true,
                text: "正在导出！",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            const id = this.carMsg.inspectionId
                ? this.carMsg.inspectionId
                : null;
            this.$axiosReq(
                carCheckExcel,
                null,
                {
                    id: id,
                },
                "get"
            )
                .then((res) => {
                    bytesToFile(res.data.bytes, res.data.fileName);
                })
                .finally(() => {
                    loading.close();
                });
        },
        getList() {
            this.loading = true;
            this.$axiosReq(carCheck + this.row.id, null, this.condition, "get")
                .then((res) => {
                    if (res.data) {
                        this.table.list = res.data.carInspectionInfoVos;
                        this.carMsg = res.data;
                    } else {
                        this.table.list = [];
                        this.carMsg = {};
                    }
                })
                .finally((e) => {
                    this.loading = false;
                });
        },
        handleSizeChange(pageSize) {
            this.table.pageSize = pageSize;
            this.table.pageNum = 1;
            this.getList();
        },
        handleCurrentChange(pageNum) {
            this.table.pageNum = pageNum;
            this.getList();
        },
        // 关闭模态框
        close() {
            this.show = false;
            this.$emit("refresh");
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep .pop-left {
    left: 1060px !important;
}

::v-deep .el-tooltip__popper.is-light {
    border: none !important;
}
</style>
