<template>
    <el-dialog
        custom-class="userBox"
        :visible.sync="show"
        width="60%"
        :append-to-body="true"
        :destroy-on-close="true"
        :close-on-click-modal="false"
        @close="close"
    >
        <div slot="title" class="header-title">
            <el-row :gutter="5">
                <el-col :span="24">
                    <span class="title-name">{{ info.title }}</span>
                </el-col>
            </el-row>
        </div>

        <el-row type="flex">
            <el-col
                :span="6"
                style="border: 1px solid #e6ebf5; padding: 0px 0 0px 16px"
            >
                <div class="left-title">人员姓名</div>
                <div style="margin-bottom: 10px; padding-right: 16px">
                    <el-input
                        size="small"
                        placeholder="请输入人员姓名搜索"
                        v-model="searchName"
                        class="input-with-select"
                        :clearable="true"
                        @input="searchNameClick"
                    >
                    </el-input>
                </div>
                <el-empty
                    description="暂无数据"
                    v-if="user.length == 0"
                ></el-empty>
                <div class="checkbox-list" v-infinite-scroll="load" v-else>
                    <el-checkbox-group v-model="form.userIds">
                        <el-checkbox
                            v-for="(v, i) in showUser"
                            :label="v"
                            :key="i"
                            :value="v"
                            style="width: 90%; height: 30px"
                            >{{ v.realName }}&nbsp;&nbsp;&nbsp;{{
                                v.phone
                            }}</el-checkbox
                        >
                    </el-checkbox-group>
                </div>
            </el-col>
            <el-col :span="2" class="center-btn-box">
                <el-button
                    type="primary"
                    plain
                    size="small"
                    @click="onMoveRight"
                    >绑定<i class="el-icon-d-arrow-right el-icon--right"></i
                ></el-button>
            </el-col>
            <el-col :span="16" style="border: 1px solid #e6ebf5; padding: 16px">
                <div class="left-title">已绑定驾驶员</div>
                <vxe-table
                    ref="table"
                    border
                    resizable
                    :auto-resize="true"
                    :header-row-style="headerStyle"
                    :row-style="rowStyle"
                    align="center"
                    class="vxe-table-element"
                    height="460"
                    row-id="notice_id"
                    :sort-config="{ remote: true }"
                    :filter-config="{ remote: true }"
                    :checkbox-config="{ reserve: true }"
                    :row-config="{ isHover: true }"
                    :data="table.showList"
                    :loading="loading"
                >
                    <vxe-table-column type="seq" width="60" />
                    <vxe-table-column
                        field="realName"
                        title="姓名"
                        show-overflow="title"
                    />

                    <vxe-table-column
                        field="departName"
                        title="部门"
                        show-overflow="title"
                    />
                    <vxe-table-column
                        field="positionName"
                        title="岗位"
                        show-overflow="title"
                    />

                    <vxe-table-column field="" title="操作">
                        <template v-slot="{ row, rowIndex }">
                            <el-button type="text" @click="removeTableItem(row)"
                                >解除绑定</el-button
                            >
                        </template>
                    </vxe-table-column>
                </vxe-table>
                <div style="height: 20px" />
                <el-pagination
                    background
                    :current-page="table.currentPage"
                    :page-sizes="[5, 10, 15, 20, 50, 100]"
                    :page-size="table.pageSize"
                    layout="total, ->, sizes, prev, pager, next, jumper"
                    :total="table.total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                />
            </el-col>
        </el-row>

        <!-- <div class="block flex-row-box flex-cont-rl pt-2">
			<div></div>
			<el-pagination @current-change="handleCurrentChange" :current-page.sync="page" :page-size="52" layout="total, prev, pager, next"
			 :total="totalPage">
			</el-pagination>
		</div> -->
        <div slot="footer" class="dialog-footer" style="text-align: center">
            <el-button type="primary" @click="commit">提交</el-button>
            <el-button type="info" @click="close">取消</el-button>
        </div>
    </el-dialog>
</template>

<script>
import { rowStyle, headerStyle } from "@/utils/tableStyleJs";
import { carBind, user } from "@/api";
export default {
    name: "User",

    props: {
        // 打开dialog的
        dialog: {
            type: Boolean, // 指定传入的类型
            // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
            default: false, // 这样可以指定默认的值
        },
        info: {
            type: Object, // 指定传入的类型
            // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
            default: () => {}, // 这样可以指定默认的值
        },
        row: {
            type: Object, // 指定传入的类型
            // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
            default: () => {}, // 这样可以指定默认的值
        },
    },
    data() {
        return {
            show: this.dialog,
            form: {
                userIds: [],
            },
            user: [],
            showUser: [],
            pageSize: 10000,
            currentPage: 1,
            page: 1,
            searchName: "",
            totalPage: 0,
            checkAll: false,
            loading: false,
            table: {
                list: [],
                showList: [],
                total: 0,
                currentPage: 1,
                pageSize: 5,
                sortName: "",
                sortBy: "",
            },
        };
    },
    created() {
        this.getUser();
        this.getList();
        // this.getCarUser();
    },
    methods: {
        rowStyle,
        headerStyle,
        handleCheckAllChange(val) {
            this.form.userIds = [];
            if (val) {
                for (let i = 0; i < this.user.length; i++) {
                    this.form.userIds.push(this.user[i].id);
                }
            }
        },
        // 从列表移除 到左边
        removeTableItem(row) {
            this.table.list = this.table.list.filter(
                (item) => item.id != row.id
            );
            this.table.showList = this.table.showList.filter(
                (item) => item.id != row.id
            );
            const startIndex =
                (this.table.currentPage - 1) * this.table.pageSize;
            this.table.showList = this.table.list.slice(
                startIndex,
                startIndex + this.table.pageSize
            );
            this.table.total = this.table.list.length;

            let flag = false;
            this.showUser.forEach((obj) => {
                if (row.id == obj.id) {
                    flag = true;
                    return;
                }
            });
            if (!flag) {
                this.showUser.push(row);
                this.user.push(row);
            }

            if (
                (this.table.currentPage - 1) * this.table.pageSize ==
                    this.table.list.length &&
                this.table.currentPage != "1"
            ) {
                this.table.currentPage = this.table.currentPage - 1;
                this.handleCurrentChange(this.table.currentPage);
            }
            //
        },
        load() {
            // if (this.currentPage < this.totalPage) {
            //     this.currentPage = this.currentPage + 1;
            //     this.getUser();
            // }
        },

        // 将选中用户移动到右边表格列表
        onMoveRight() {
            this.form.userIds.forEach((item) => {
                let flag = false;
                this.table.list.forEach((obj) => {
                    if (item.id == obj.id) {
                        flag = true;
                        return;
                    }
                });
                if (!flag) {
                    this.table.list.push(item);
                }
                // if (!this.table.list.includes(item)) {
                //     this.table.list.push(item);
                // }
            });

            this.table.total = this.table.list.length;

            // 表格数据分页展示

            // 算出当前索引
            const startIndex =
                (this.table.currentPage - 1) * this.table.pageSize;
            this.table.showList = this.table.list.slice(
                startIndex,
                startIndex + this.table.pageSize
            );

            // 将左边选中的数据移除
            this.showUser = this.showUser.filter((item) => {
                let flag = false;
                this.form.userIds.forEach((obj) => {
                    if (item.id == obj.id) {
                        flag = true;
                    }
                });
                return !flag;
            });

            this.user = this.user.filter((item) => {
                let flag = false;
                this.form.userIds.forEach((obj) => {
                    if (item.id == obj.id) {
                        flag = true;
                    }
                });
                return !flag;
            });
            this.form.userIds = [];
        },

        // 获取已绑定列表
        getList() {
            this.loading = true;
            this.$axiosReq(
                `/car/server/escort/web/CarInspection/bind/user/${this.row.id}`,
                null,
                null,
                "get"
            )
                .then((res) => {
                    this.table.list = res.data;
                    this.table.total = Number(res.data.length);

                    const startIndex =
                        (this.table.currentPage - 1) * this.table.pageSize;
                    this.table.showList = this.table.list.slice(
                        startIndex,
                        startIndex + this.table.pageSize
                    );
                })
                .finally((res) => {
                    this.loading = false;
                });
        },

        // 获取用户
        getUser() {
            // var that_ = this;
            // this.$axiosReq(
            //     user,
            //     null,
            //     {
            //         pageSize: that_.pageSize,
            //         currentPage: that_.currentPage,
            //         searchName: that_.searchName,
            //         companyId: that_.row.companyId,
            //     },
            //     "get"
            // ).then((res) => {
            //     that_.user =
            //         res.data.currentPage == "1"
            //             ? res.data.content
            //             : that_.user.concat(res.data.content);
            //     that_.totalPage = Number(res.data.totalPage);
            //     that_.currentPage = Number(res.data.currentPage);
            // });
            var that_ = this;
            this.$axiosReq(
                `/car/server/escort/web/CarInspection/no/bind/user/${this.row.id}`,
                null,
                {},
                "get"
            ).then((res) => {
                that_.user = res.data;
                that_.showUser = that_.user;
                that_.totalPage = Number(res.data.length);
            });
        },
        getCarUser() {
            for (const i of this.row.userMsg) {
                this.form.userIds.push(i.userId);
            }
        },
        handleSizeChange(pageSize) {
            this.table.pageSize = pageSize;
            this.table.currentPage = 1;
            // this.table.list = [];
            // this.getList();
            // 算出当前索引
            const startIndex =
                (this.table.currentPage - 1) * this.table.pageSize;
            this.table.showList = this.table.list.slice(
                startIndex,
                startIndex + this.table.pageSize
            );
        },
        handleCurrentChange(currentPage) {
            this.table.currentPage = currentPage;
            // this.table.list = [];
            // this.getList();

            const startIndex =
                (this.table.currentPage - 1) * this.table.pageSize;
            this.table.showList = this.table.list.slice(
                startIndex,
                startIndex + this.table.pageSize
            );
        },
        // 提交添加
        commit() {
            const ids = this.table.list.map((item) => item.id);
            this.$axiosReq(
                `/car/server/escort/web/CarInspection/bind/user
`,

                {
                    carId: this.row.id,
                    useIds: ids,
                },
                null,
                "post"
            ).then((res) => {
                this.close();
                this.$emit("get-list");
                this.$message.success(res.msg);
            });
        },
        // 关闭模态框
        close() {
            this.show = false;
            this.$emit("refresh");
        },

        searchNameClick() {
            this.showUser = this.user.filter((item) => {
                if (
                    (item.realName &&
                        item.realName.includes(this.searchName)) ||
                    (item.phone && item.phone.includes(this.searchName))
                ) {
                    return true;
                } else {
                    return false;
                }
            });
        },
    },
};
</script>

<style scoped>
.input-with-select {
    width: 100%;
}

.pb-20 {
    padding-bottom: 20px;
}

.left-title {
    font-size: 14px;
    color: rgb(96, 98, 102);
    margin: 10px 0;
}
.checkbox-list {
    width: 100%;
    height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
}

.center-btn-box {
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
